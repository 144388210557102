
import { Dialog } from "vant";
import Vue from 'vue'
export default {
  name: "Index",
  components: {
  },
  data() {
    return {
      disabledScroll: false
    };
  },
  methods: {
    afterLogin() {
      Vue.prototype.U = S.get('_m');
      axios.defaults.headers.common["Token"] = this.U.token;
    }
  },
  created() {

    var t = this;

    Vue.prototype.startUrl = location.href;
    Vue.prototype.busying = false;

    Vue.prototype.afterLogin = t.afterLogin;

    Dialog.setDefaultOptions({ confirmButtonText: '确认' });

    axios.defaults.baseURL = '//hk.kyoran.jp/v1/';

    axios.interceptors.response.use(function (response) {
      if (response.data.status == -5) {
        localStorage.removeItem('_m');
        location.replace('/#/signin');
      }
      return response;
    }, function (error) {
      if (error.response.status === 401) {
        console.error('Unauthorized access, please login again.');
      } else if (error.response.status === 403) {
        console.error('Access forbidden.');
      }
      return Promise.reject(error);
    });

    Vue.prototype.wsURL = 'wss://hk.kyoran.jp:8765/';

    if (/localhost|dev/.test(location.host)){
      axios.defaults.baseURL = '//localhost/kyoran/api/public/v1/';
      Vue.prototype.wsURL = 'ws://localhost:8765/';
    }

    if (S.get('_m')) {
      Vue.prototype.U = S.get('_m');
      axios.defaults.headers.common["Token"] = this.U.token;
    }
    else {
      Vue.prototype.U = {
        firstname: '',
        lastname: '',
        email: '',
        tel: '',
        last_logged_at: '',
        birthday: '',
        entry_date: '',
        emergency_tel: '',
        desc: '',
        gender: '',
        sn: '',
        token: '',
        business_ids: '',
        country: '',
        rc_img_url: '',
        id: '',
        avatar_url: '',
        rating: 0,
        count_cleaned: 0,
        count_sup: 0,
        role: 1,
      };
      if(!/form|read|working_report|business_form/.test(location.hash))
        location.replace('/#/signin');
    }


  },
  mounted() {
    let t = this;

  },

};
